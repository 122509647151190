import React, { useState, useEffect } from "react";
import { getDatabase } from "firebase/database";
import { db, auth } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { storage } from '../firebase-storage';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css'
import '../styles/VendosKursin.css'
import { doc, getDoc, collection, addDoc, serverTimestamp } from "firebase/firestore";

function VendosKurs({ isAuth }) {

  const [eurBlej, setEurBlej] = useState("");
  const [eurShes, setEurShes] = useState("");
  const [usdBlej, setUsdBlej] = useState("");
  const [usdShes, setUsdShes] = useState("");
  const [chfBlej, setChfBlej] = useState("");
  const [chfShes, setChfShes] = useState("");
  const [gbpBlej, setGbpBlej] = useState("");
  const [gbpShes, setGbpShes] = useState("");
  const [cadBlej, setCadBlej] = useState("");
  const [cadShes, setCadShes] = useState("");
  const [audBlej, setAudBlej] = useState("");
  const [audShes, setAudShes] = useState("");
  const [jpyBlej, setJpyBlej] = useState("");
  const [jpyShes, setJpyShes] = useState("");
  const [sekBlej, setSekBlej] = useState("");
  const [sekShes, setSekShes] = useState("");
  const [nokBlej, setNokBlej] = useState("");
  const [nokShes, setNokShes] = useState("");
  const [dkkBlej, setDkkBlej] = useState("");
  const [dkkShes, setDkkShes] = useState("");
  const [tryBlej, setTryBlej] = useState("");
  const [tryShes, setTryShes] = useState("");
  const [aedBlej, setAedBlej] = useState("");
  const [aedShes, setAedShes] = useState("");
  const [mkdBlej, setMkdBlej] = useState("");
  const [mkdShes, setMkdShes] = useState("");
  const [hufBlej, setHufBlej] = useState("");
  const [hufShes, setHufShes] = useState("");
  const [bgnBlej, setBgnBlej] = useState("");
  const [bgnShes, setBgnShes] = useState("");
  const [rsdBlej, setRsdBlej] = useState("");
  const [rsdShes, setRsdShes] = useState("");
  const [czkBlej, setCzkBlej] = useState("");
  const [czkShes, setCzkShes] = useState("");
  const [ronBlej, setRonBlej] = useState("");
  const [ronShes, setRonShes] = useState("");
  const [plnBlej, setPlnBlej] = useState("");
  const [plnShes, setPlnShes] = useState("");
  const [ora, setOra] = useState("");
  const [min, setMin] = useState("");
  const [dt, setDt] = useState("");

  const postsCollectionRef = collection(db, "rates");
  let navigate = useNavigate();

  const vendosKurset = async () => {

    await addDoc(postsCollectionRef, {
      eurBlej: eurBlej,
      eurShes: eurShes,
      usdBlej: usdBlej,
      usdShes: usdShes,
      chfBlej: chfBlej,
      chfShes: chfShes,
      gbpBlej: gbpBlej,
      gbpShes: gbpShes,
      cadBlej: cadBlej,
      cadShes: cadShes,
      audBlej: audBlej,
      audShes: audShes,
      jpyBlej: jpyBlej,
      jpyShes: jpyShes,
      sekBlej: sekBlej,
      sekShes: sekShes,
      nokBlej: nokBlej,
      nokShes: nokShes,
      dkkBlej: dkkBlej,
      dkkShes: dkkShes,
      tryBlej: tryBlej,
      tryShes: tryShes,
      aedBlej: aedBlej,
      aedShes: aedShes,
      mkdBlej: mkdBlej,
      mkdShes: mkdShes,
      hufBlej: hufBlej,
      hufShes: hufShes,
      bgnBlej: bgnBlej,
      bgnShes: bgnShes,
      rsdBlej: rsdBlej,
      rsdShes: rsdShes,
      czkBlej: czkBlej,
      czkShes: czkShes,
      ronBlej: ronBlej,
      ronShes: ronShes,
      plnBlej: plnBlej,
      plnShes: plnShes,
      timestamp: serverTimestamp(),
      ora: ora,
      min: min,
      dt: dt
    });

  };

  useEffect(() => {
    if (!isAuth) {
      navigate("/loginalbkredit-admin");
    }
  }, []);

  return (
    <>
      <div style={{ 'marginTop': '180px', 'marginBottom': '150px' }}>
        <div className='container vendosKursTable'>

          <h1 className="vendosKurs">Vendos kursin ditor</h1>

          <div className="row row-cols-1">

            <div className="col">
              <div className='curBlej'>
                <label>USD Blejme</label>
                <input
                  onChange={(event) => {
                    setUsdBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>USD Shesim</label>
                <input
                  onChange={(event) => {
                    setUsdShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className='col'>
              <div className='curBlej'>
                <label>EUR Blejme</label>
                <input
                  onChange={(event) => {
                    setEurBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>EUR Shesim</label>
                <input
                  onChange={(event) => {
                    setEurShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>CHF Blejme</label>
                <input
                  onChange={(event) => {
                    setChfBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>CHF Shesim</label>
                <input
                  onChange={(event) => {
                    setChfShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>GBP Blejme</label>
                <input
                  onChange={(event) => {
                    setGbpBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>GBP Shesim</label>
                <input
                  onChange={(event) => {
                    setGbpShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>CAD Blejme</label>
                <input
                  onChange={(event) => {
                    setCadBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>CAD Shesim</label>
                <input
                  onChange={(event) => {
                    setCadShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>AUD Blejme</label>
                <input
                  onChange={(event) => {
                    setAudBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>AUD Shesim</label>
                <input
                  onChange={(event) => {
                    setAudShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>JPY Blejme</label>
                <input
                  onChange={(event) => {
                    setJpyBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>JPY Shesim</label>
                <input
                  onChange={(event) => {
                    setJpyShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>SEK Blejme</label>
                <input
                  onChange={(event) => {
                    setSekBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>SEK Shesim</label>
                <input
                  onChange={(event) => {
                    setSekShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>NOK Blejme</label>
                <input
                  onChange={(event) => {
                    setNokBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>
                  NOK Shesim</label>
                <input
                  onChange={(event) => {
                    setNokShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>DKK Blejme</label>
                <input
                  onChange={(event) => {
                    setDkkBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>DKK Shesim</label>
                <input
                  onChange={(event) => {
                    setDkkShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>TRY Blejme</label>
                <input
                  onChange={(event) => {
                    setTryBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>TRY Shesim</label>
                <input
                  onChange={(event) => {
                    setTryShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>AED Blejme</label>
                <input
                  onChange={(event) => {
                    setAedBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>AED Shesim</label>
                <input
                  onChange={(event) => {
                    setAedShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>MKD Blejme</label>
                <input
                  onChange={(event) => {
                    setMkdBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes'>
                <label>MKD Shesim</label>
                <input
                  onChange={(event) => {
                    setMkdShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>HUF Blejme</label>
                <input
                  onChange={(event) => {
                    setHufBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>HUF Shesim</label>
                <input
                  onChange={(event) => {
                    setHufShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>BGN Blejme</label>
                <input
                  onChange={(event) => {
                    setBgnBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>BGN Shesim</label>
                <input
                  onChange={(event) => {
                    setBgnShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>RSD Blejme</label>
                <input
                  onChange={(event) => {
                    setRsdBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>RSD Shesim</label>
                <input
                  onChange={(event) => {
                    setRsdShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>CZK Blejme</label>
                <input
                  onChange={(event) => {
                    setCzkBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>CZK Shesim</label>
                <input
                  onChange={(event) => {
                    setCzkShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>RON Blejme</label>
                <input
                  onChange={(event) => {
                    setRonBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>RON Shesim</label>
                <input
                  onChange={(event) => {
                    setRonShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>PLN Blejme</label>
                <input
                  onChange={(event) => {
                    setPlnBlej(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>PLN Shesim</label>
                <input
                  onChange={(event) => {
                    setPlnShes(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>ORA</label>
                <input
                  onChange={(event) => {
                    setOra(event.target.value);
                  }} />
              </div>
              <div className='curShes '>
                <label>MINUTA</label>
                <input
                  onChange={(event) => {
                    setMin(event.target.value);
                  }} />
              </div>
            </div>

            <div className="col">
              <div className='curBlej'>
                <label>DATA</label>
                <input
                  onChange={(event) => {
                    setDt(event.target.value);
                  }} />
              </div>
            </div>

          </div>

          <br></br>

          <button className="btn btn-primary m-4" onClick={vendosKurset} type='button'>Submit</button>

        </div>
      </div>
    </>

  )

}


export default VendosKurs;
