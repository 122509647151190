import React, { useEffect, useMemo, useState } from 'react';
import { getDocs, collection, query, orderBy, limit, Timestamp } from "firebase/firestore";
import { db } from "../firebase-config";
import Accordion from 'react-bootstrap/Accordion';
import '../styles/Konverto.css'
import "react-bootstrap";
import { useContext } from 'react';
import { Context } from '../context';

const keys = {
  'eur': 'EUR (Euro)',
  'usd': 'USD (Dollar Amerikan)',
  'chf': 'CHF (Frangë Zviceriane)',
  'gbp': 'GBP (Paund Britanik)',
  'cad': 'CAD (Dollar Kanadez)',
  'aud': 'AUD (Dollar Australian)',
  'jpy': 'JPY (Jen Kandez)',
  'sek': 'SEK (Korona Suedeze)',
  'nok': 'NOK (Korona Norvegjeze)',
  'dkk': 'DKK (Korona Daneze)',
  'try': 'TRY (Lira Turke)',
  'aed': 'AED (Dirham Emiratet e Bashkuara Arabe)',
  'mkd': 'MKD (Denari Maqedonas)',
  'huf': 'HUF (Forinta Hungareze)',
  'bgn': 'BGN (Leva Bullgare)',
  'rsd': 'RSD (Dinari Serb)',
  'czk': 'CZK (Korona Çeke)',
  'ron': 'RON (Lei Rumun)',
  'pln': 'PLN (Zloty Polak)'
}

function Convert() {

  const [postLists, setPostList] = useState([]);
  const postsCollectionRef = collection(db, "rates");

  const { t } = useContext(Context)

  useEffect(() => {
    const getPosts = async () => {

      const q = query(postsCollectionRef, orderBy("timestamp", "desc"), limit(1));

      const data = await getDocs(q);

      setPostList((data.docs || []).map((doc) => ({ ...doc.data(), id: doc.id })));

    };

    getPosts();
  }, []);

  const [val, setVal] = useState();
  const [val2, setVal2] = useState();
  const [val3, setVal3] = useState();
  const [val4, setVal4] = useState();
  const [key, setKey] = useState();
  const [key2, setKey2] = useState();
  const [key3, setKey3] = useState();
  const [key4, setKey4] = useState();

  const checkBlej = function (val, val2) {
    if (isNaN(val / val2 * convertValBlej)) {
      return 0
    } else if (!isNaN(val / val2 * convertValBlej)) {
      return val / val2 * convertValBlej
    }
  }

  const checkShes = function (val4, val3) {
    if (isNaN(val4 / val3 * convertValShes)) {
      return 0
    } else if (!isNaN(val4 / val3 * convertValShes)) {
      return val4 / val3 * convertValShes
    }
  }

  const [convertValBlej, setConvertValBlej] = useState();
  const [convertValShes, setConvertValShes] = useState();

  const handleChangeBlej = event => {
    setConvertValBlej(event.target.value);
  }

  const handleChangeShes = event => {
    setConvertValShes(event.target.value);
  }

  const firstValues = useMemo(() => {
    if (postLists[0]) {
      const first = postLists[0];
      return [
        {
          key: 'alb',
          label: 'Lek',
          value: 1,
        },
        ...Object.keys(keys).map(k => ({
          key: k,
          label: keys[k],
          value: first[`${k}Shes`],
        }))
      ];
    }
  }, [postLists]);

  const secondValues = useMemo(() => {
    if (postLists[0]) {
      const first = postLists[0];
      return [
        {
          key: 'alb',
          label: 'Lek',
          value: 1,
        },
        ...Object.keys(keys).map(k => ({
          key: k,
          label: keys[k],
          value: first[`${k}Blej`],
        }))
      ];
    }
  }, [postLists]);

  return (
    <div>
      {postLists.map((post) => {

        return (
          <>
            <div style={{ 'marginTop': '250px' }}></div>

            <Accordion defaultActiveKey="0" flush>
              <Accordion.Item eventKey="0" className='accordion'>
                <Accordion.Header >{t('KLIENTI BLEN')}</Accordion.Header>
                <Accordion.Body>
                  <form>
                    <label>{t('Konverto')}</label>
                    <select value={key}
                      onChange={(e) => {
                        const v = firstValues.find(v => v.key === e.target.value);
                        setVal(v.value)
                        setKey(e.target.value);
                      }}>
                      <option > {t('Nga')} </option>
                      {firstValues.filter(v => v.key !== key2).map(v => (
                        <option value={v.key} key={`shes-1-${v.key}`}>
                          {v.label}
                        </option>
                      ))}
                    </select>

                    <select value={key2}
                      onChange={(e) => {
                        const v = secondValues.find(v => v.key === e.target.value);
                        setVal2(v.value)
                        setKey2(e.target.value);
                      }}>
                      <option > {t('Në')} </option>
                      {secondValues.filter(v => v.key !== key).map(v => (
                        <option value={v.key} key={`blej-1-${v.key}`}>
                          {v.label}
                        </option>
                      ))}
                    </select>

                    {/* <h1>Selected 1:  {val} Selected 2 {val2}</h1> */}

                    <label> {t('Shuma:')}
                      <input id='convertValue' type="text" onChange={handleChangeBlej} value={convertValBlej} />
                    </label>
                    <label>{t('Rezultati:')}</label>
                    <label className='res'> {Number(checkBlej(val, val2)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                    <button className='reset' onClick={document.getElementsByTagName('select').clear}>{t('Pastro')}</button>
                  </form>
                </Accordion.Body>

              </Accordion.Item>
              <Accordion.Item eventKey="1" className='accordion'>
                <Accordion.Header >{t('KLIENTI SHET')}</Accordion.Header>
                <Accordion.Body>
                  <form>
                    <label>{t('Konverto')}</label>
                    <select value={key3}
                      onChange={(e) => {
                        const v = secondValues.find(v => v.key === e.target.value);
                        setVal4(v.value)
                        setKey3(e.target.value);
                      }}>
                      <option > {t('Nga')} </option>
                      {secondValues.filter(v => v.key !== key4).map(v => (
                        <option value={v.key} key={`blej-2-${v.key}`}>
                          {v.label}
                        </option>
                      ))}
                    </select>

                    <select value={key4}
                      onChange={(e) => {
                        const v = firstValues.find(v => v.key === e.target.value);
                        setVal3(v.value)
                        setKey4(e.target.value);
                      }}>
                      <option > {t('Në')} </option>
                      {firstValues.filter(v => v.key !== key3).map(v => (
                        <option value={v.key} key={`shes-1-${v.key}`}>
                          {v.label}
                        </option>
                      ))}
                    </select>


                    {/* <h1>Selected 1:  {val4} Selected 2 {val3}</h1> */}

                    <label> {t('Shuma:')}
                      <input id='convertValue' type="text" onChange={handleChangeShes} value={convertValShes} />
                    </label>
                    <label>{t('Rezultati:')}</label>
                    <label className='res'> {Number(checkShes(val4, val3)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</label>
                    <button className='reset' onClick={document.getElementsByTagName('select').clear}>{t('Pastro')}</button>
                  </form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>


          </>
        );
      })}
    </div>
  )
}

export default Convert